/* eslint-disable */
import React, { useEffect, useState, useCallback } from "react";
import "./styles.less";
import {
  Divider,
  Row,
  Col,
  Radio,
  Checkbox,
  Input,
  Select,
  Modal,
  message,
  Tooltip,
  Spin,
} from "antd";
import { useMeasure, useMount, useSetState, useUnmount } from "react-use";
import { QueryGraphQL } from "../../../../../Queries/GraphQl";
import Common from "../../../../../../Utils/Common";
import _ from "lodash";
import TextArea from "antd/lib/input/TextArea";
import { Observable, Observer } from "../../../../../../Utils/Ob";
import Swal from "sweetalert2";
import Configuration from "../../../../../../Config/Config";
import { unlockCourseLessonUserAction } from "../../../../../Actions/Course";
import { CourseListState } from "../../../../../Router/Layout/maxcoach/CourseHeader/CourseList";
import moment from "moment";
import {
  StyledBannerInfo,
  StyledDivider,
  StyledFixPosition,
  StyledLayout,
  StyledMultipleLineIndent,
  StyledQuestion,
  StyledQuestionInfo,
  StyledStartButton,
} from "./styledComponents";
import ThreeDotsLoader from "../../../../ComponentCommon/ThreeDotsLoader";
import { hintTextColor } from "../../../../../../Utils/themeConfig";
import { useQuizSecurity } from "../../../../ComponentCommon/QuizSecurity";
import { useDropzone } from "react-dropzone";
import { FileTextOutlined, InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import { uploadFileContest } from "../../../../../Services/FileService";
import { getCommonStorage } from "../../../../../../Config/gql";
import Dragger from "antd/lib/upload/Dragger";
import axios from "axios";
import ShortAnswer from "./ShortAnswer";
import { useQuery } from "urql";
import { queryVideoQuizs } from "../VideoQuizs/gql";

function MaxQuizDetail(props) {
  const [fetchQuiz] = useQuery({
    query: queryVideoQuizs,
    variables: (() => {
      const { merchantId, languageId, tokenId } = getCommonStorage();

      return {
        merchantId,
        languageId,
        id: props.id,
tokenUser: tokenId
      };
    })(),
    pause: !props.id,
  });
  const [state, setState] = useSetState({
    infoQuiz: null,
    answers: {},
    started: false,
    done: false,
    uuid: null,
    review: false,
    loading: false,
  });
  const [questions, setQuestions] = useState([]);
  const [titleRef, titleRefInfo] = useMeasure();
  const [PiP] = useQuizSecurity({ show: false });

  const _updateScore = (score) => {

    //find quiz certificate
    if(CourseListState.userCourse != undefined){
      let currentQuiz = CourseListState.userCourse.quizs.find(quiz=>quiz.id === props.id);
    
      //find quiz lesson
      if (CourseListState.userCourse?.type === Configuration.courseType.conditionQuiz) {
        let userCourse = _.cloneDeep(CourseListState.userCourse);
        let lesson = CourseListState.selected.lesson;
        // let item = CourseListState.selected.item;
        let foundIndex = userCourse.courseLessons.findIndex((x) => x.id === lesson.id);
        if(currentQuiz == undefined){
          currentQuiz = userCourse.courseLessons[foundIndex].quizs.find(quiz=>quiz.id === props.id);
        }
  
        if (foundIndex < userCourse.courseLessons.length - 1) {
          if (userCourse.courseLessons[foundIndex + 1].locked === false) {
            try{
              if ((score / parseFloat(currentQuiz.totalScore)) * 100.0 >=lesson.conditionValue) {
                // if (score >= +lesson.conditionValue) {
                unlockCourseLessonUserAction(userCourse.courseLessons[foundIndex + 1].id);
                userCourse.courseLessons[foundIndex + 1].locked = true;
                CourseListState.userCourse = userCourse;
                Modal.success({
                  content: "Bạn đã mở khóa bài học tiếp theo thành công.",
                });
              } else {
                Modal.warning({
                  content: "Chưa đủ điểm để mở khóa bài học kế tiếp.",
                });
              }
            }catch{
  
            }
           
          }
        }
      }
    }    
  };
  const _start = (force) => {
    const onOK = () => {
      let uuid = Common.guid();
      setState({ uuid, loading: true });
      message.loading({
        content: "Đang bắt đầu...",
        key: "start",
      });

      Common.sendSyncCommand(
        CourseListState.currentIsLastQuiz ? "StartCertificateQuiz" : "StartQuiz",
        "CustomerRelationshipManagement",
        {
          Id: uuid,
          QuizId: props.id,
          UserId: localStorage.getItem(Configuration.userId),
          CommandInformation: navigator.userAgent,
          ModifiedDate: Common.formatDateTime(new Date()),
          ModifiedBy: localStorage.getItem(Configuration.userId),
          CourseId: CourseListState.userCourse?.id,
        },
        () => {
          CountDownState.seconds = state.infoQuiz?.duration;
          CountDownState.pause = false;
          setState({ started: true, answers: {}, done: false, loading: false });
          message.success({ key: "start", content: "Bắt đầu tính thời gian!" });
        },
        (res) => {
          Swal.fire(res.Message).then((result) => {
            if (result.value) {
              window.location.reload();
            }
          });
        }
      );
    };

    if (force) {
      onOK();
    } else {
      Modal.confirm({
        title: `Thời gian làm bài ${duration2Text(
          state.infoQuiz?.duration
        )}. Xác nhận bắt đầu làm bài?`,
        okText: "Bắt đầu",
        cancelText: "Chưa sẵn sàng",
        onOk: onOK,
        onCancel() {},
      });
    }
  };
  const _done = (force) => {
    if (state.done) return;

    async function done() {
      CountDownState.pause = true;
      setState({ done: true, loading: true });
      message.loading({
        key: "done",
        content: "Đang nộp bài...",
      });

      let finalAnswers = [];

      _.forEach(questions, (question) => {
        let files = []
        let item = {
          id: question.id,
          answers: [],
          files: [],
        };
        let userAnswer = state.answers[question.id];

        if (["single", "multiple"].includes(question.type)) {
          _.forEach(question.answers, (answer) => {
            if (
              answer.id === userAnswer ||
              (Array.isArray(userAnswer) && userAnswer.includes(answer.id))
            ) {
              item.answers.push({
                id: answer.id,
                name: "",
              });
            }
          });
        } else if (question.type === "input") {
          _.forEach(userAnswer, (answer, id) => {
            if (answer.files) {
              answer.files.forEach((file, index) => {
                item.files.push({
                  Id: file.id,
                  IsFeatured: false,
                  DisplayOrder: index + 1,
                });
              });
            }
            item.answers.push({
              id,
              name: answer.text,
            });
          });
        } else if (question.type === "fill") {
          _.forEach(userAnswer, (userAnswer, id) => {
            if (Array.isArray(userAnswer)) {
              // data: _fill lorem__section_ [undefined, "fill text", "fill text", undefined, "fill text"]
              if (userAnswer.filter((v) => v !== undefined).length > 0) {
                let splitedName = question.name.split("_");
                let stringBuilder = [];

                splitedName.forEach((v, i) => {
                  if (userAnswer[i] !== undefined) {
                    stringBuilder.push(`_{{${userAnswer[i]}}}_`);
                  }
                  stringBuilder.push(v);
                });

                item.answers.push({
                  id,
                  name: stringBuilder.join(""),
                });
              }
            }
          });
        } else if (question.type === "matching") {
          // data: {[questionId]: {[prefix]: suffix}}
          console.log(question);
          var indexUserAnser = 0;
          _.forEach(userAnswer, (suffix, prefix) => {
            if (!suffix) suffix = "";
            
            item.answers.push({
              //id: question.id,
              id: question.answers[indexUserAnser].id,
              name: [prefix, suffix].join("_"),
            });
            indexUserAnser++;
          });

          _.forEach(question.answers, (answer) => {
            let [prefix] = answer.name.split("_");
            
            if (userAnswer && !userAnswer[prefix]) {
              
              item.answers.push({
                id: question.id,
                name: [prefix, ""].join("_"),
              });
            }
          });
        }

        finalAnswers.push(item);
      });

      console.log(finalAnswers, "finalAnswers");

      const data = {
        Id: state.uuid,
        UserId: localStorage.getItem(Configuration.userId),
        UserDeviceToken: "",
        AnswerQuestions: finalAnswers,
        CommandInformation: navigator.userAgent,
        ModifiedDate: Common.formatDateTime(new Date()),
        ModifiedBy: localStorage.getItem(Configuration.userId),
      };

      if (CourseListState.currentIsLastQuiz) {
        data.CourseId = CourseListState.userCourse.id;
      }

      Common.sendSyncCommand(
        CourseListState.currentIsLastQuiz ? "FinishCertificateQuiz" : "FinishQuiz",
        "CustomerRelationshipManagement",
        data,
        (data) => {
          message.success({ key: "done", content: "Nộp bài thành công." });

          if (state.infoQuiz.options?.ShowScore) {
            _updateScore(+data.Message);
            Modal.success({
              title: "Bạn đã nộp bài thành công",
              content: `Điểm của bạn là: ${+data.Message}`,
              okText: "Xác nhận",
            });
          } else {
            Modal.success({
              title: "Bạn đã nộp bài thành công",
              content: `Điểm của bạn sẽ được giảng viên thông báo sau.`,
              okText: "Xác nhận",
            });
          }

          if (CourseListState.currentIsLastQuiz && state.infoQuiz.options?.ShowScore) {
            CourseListState.reloadCourse = true;
          }

          setState({ started: false, review: true, loading: false });
        },
        () => {
          setState({ done: false, started: false, loading: false });
          message.error({ key: "done", content: "Có lỗi xảy ra." });
        }
      );
    }

    if (force) {
      done().catch(console.error);
    } else {
      Modal.confirm({
        content: "Bạn có muốn nộp bài ngay bây giờ không?",
        okText: "Xác nhận",
        cancelText: "Quay lại làm bài",
        onOk() {
          return new Promise((rel, rej) => {
            done().then(rel).catch(rej);
          }).catch((e) => console.error(e));
        },
        onCancel() {},
      });
    }
  };
  const _startEndQuiz = () => {
    if (!state.infoQuiz || state.loading) return;
    if (state.started) {
      _done();
    } else {
      _start();
    }
  };

  useMount(() => {
    setState({ loading: true });
    QueryGraphQL.getDetailQuiz(props.id)
      .then((data) => {
        if (data.quiz != null) {
          let infoQuiz = data.quiz;

          if (typeof infoQuiz.option === "string") {
            try {
              infoQuiz.options = JSON.parse(infoQuiz.option);
            } catch (e) {}
          }

          if (infoQuiz.images && infoQuiz.images.length > 0) {
            infoQuiz.background = Common.getImageThumbnail(
              infoQuiz.images,
              "?mode=crop&width=900&height=280"
            );
          } else {
            infoQuiz.background =
              "https://static.izzi.asia/images/quiz-banner.png?mode=crop&width=900&height=280";
          }
          let tempQuestions = [...infoQuiz.questions];
          if (!infoQuiz.options.RandomDisplayOrderQuestion) {
            tempQuestions.sort((a, b) => a.displayOrder - b.displayOrder);
          } else {
            tempQuestions = _.shuffle(infoQuiz.questions);
            tempQuestions.forEach((item) => {
              item.answers = _.shuffle(item.answers);
            });
          }

          tempQuestions = (tempQuestions || []).map((question) => {
            let type = +question.type;
            question.typeByNumber = type;

            if (type === Math.pow(2, 0)) {
              question.type = "single";
            } else if (type === Math.pow(2, 1)) {
              question.type = "single";
            } else if (type === Math.pow(2, 2)) {
              question.type = "multiple";
            } else if (type === Math.pow(2, 3)) {
              question.type = "input";
            } else if (type === Math.pow(2, 4)) {
              question.type = "fill";
            } else if (type === Math.pow(2, 5)) {
              question.type = "matching";
            }

            return question;
          });
          console.log(tempQuestions, "334");
          let answers = {};

          _.forEach(tempQuestions, (question) => {
            answers[question.id] = null;
          });

          setQuestions([...tempQuestions]);
          setState({ infoQuiz, answers });
          console.log(infoQuiz, "334");
        }
      })
      .finally(() => {
        setState({ loading: false });

        if (props.autostart) {
          setTimeout(() => {
            _start(true);
          }, 1000);
        }
      });
  });

  let scoreQuiz = 0;
  _.forEach(state.infoQuiz?.questions, (e) => (scoreQuiz += e.point));
  /*  let questions = (state.infoQuiz?.questions) || [];
  

  if (!state.infoQuiz?.options?.RandomDisplayOrderQuestion) {
    
    (questions.sort((a, b) => a.displayOrder - b.displayOrder));
  } */

  return (
    <React.Fragment>
      <div
        className={"right-section hover"}
        style={{
          padding: 0,
          border: 0,
          position: "relative",
          borderRadius: 5,
          overflow: "hidden",
        }}>
        <img
          src={state.infoQuiz?.background}
          alt={"bg"}
          style={{
            height: 280,
            objectFit: "cover",
            width: "100%",
            display: "block",
          }}
        />
        <div className={"d-bg-info"}>
          <StyledBannerInfo>
            <div className={"quiz-item"}>QUIZ</div>
            <div className={"header-item"} ref={titleRef}>
              <span style={{ width: titleRefInfo.width }}>
                <Tooltip title={state.infoQuiz?.name}>{state.infoQuiz?.name}</Tooltip>
              </span>
            </div>
            <div className={"info-item"}>
              <span>
                Thời gian: <b>{duration2Text(state.infoQuiz?.duration)}</b>
              </span>
              <span>
                Quiz: <b>{state.infoQuiz?.questions?.length} câu hỏi</b>
              </span>
              <span>
                Tổng: <b>{scoreQuiz} điểm</b>
              </span>
            </div>
          </StyledBannerInfo>
        </div>
      </div>
      {/**/}
      <RenderQuestions
        questions={questions}
        onAnswers={(answers) => setState({ answers })}
        {...state}
      />
      <div
        className={"right-section"}
        style={{
          background: "unset",
          boxShadow: "unset",
          padding: 0,
          position: "sticky",
          bottom: 0,
          zIndex: 99,
        }}>
        <StyledLayout align="center">
          <StyledStartButton onClick={_startEndQuiz}>
            <span hidden={state.loading}>
              {!(state.done || state.started) && "Bắt đầu"}
              {state.done && "Làm lại"}
              {!(state.done || !state.started) && (
                <React.Fragment>
                  <span>{CountDownState.seconds > 0 ? "Nộp bài" : "Đã hết thời gian làm bài"}</span>
                  <CountDown
                    seconds={state.infoQuiz?.duration}
                    onDone={() => state.started && _done(true)}
                  />
                </React.Fragment>
              )}
            </span>
            <span hidden={!state.loading}>
              <ThreeDotsLoader width={60} height={22} fill={"#FFFFFF"} />
            </span>
          </StyledStartButton>
        </StyledLayout>
      </div>
      {PiP}
    </React.Fragment>
  );
}

export function RenderQuestions({ questions, onAnswers, ...state }) {
  const [submitting, setSubmitting] = useState(false);

  questions = (questions || []).map((question) => {
    let type = +question.type;
    question.typeByNumber = type;

    if (type === Math.pow(2, 0)) {
      question.type = "single";
    } else if (type === Math.pow(2, 1)) {
      question.type = "single";
    } else if (type === Math.pow(2, 2)) {
      question.type = "multiple";
    } else if (type === Math.pow(2, 3)) {
      question.type = "input";
    } else if (type === Math.pow(2, 4)) {
      question.type = "fill";
    } else if (type === Math.pow(2, 5)) {
      question.type = "matching";
    }

    return question;
  });
  const uploadFileContes = async (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      const commonStorage = getCommonStorage();
      formData.append("token", window.localStorage.getItem("tokenId"));

      formData.append("UploadedFile", file);

      var request = new XMLHttpRequest();
      request.open("POST", "https://apirestful.izzi.asia/File/UploadFileContest");
      request.send(formData);
      request.onload = function () {
        var jsonResponse = JSON.parse(request.responseText);
        resolve({
          success: jsonResponse.Success,
          path: jsonResponse.Data.Path,
          fileId: jsonResponse.Data.Id,
          name: jsonResponse.Data.Name,
          message: jsonResponse.Data.Message || jsonResponse.Message,
        });
      };
    });
  };
  const setState = ({ answers }) => {
    onAnswers && onAnswers(answers);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return questions.map((question, i) => {
    /*  if (question.answers?.length) {
      question.answers.sort((a, b) => a.displayOrder - b.displayOrder);
    } */

    const infoDivider = (() => {
      if (question.type === "single") return "Lựa chọn câu trả lời";
      if (question.type === "multiple") return "Lựa chọn một hoặc nhiều câu trả lời";
      if (question.type === "input") return "Viết câu trả lời";
      if (question.type === "fill") return "Điền câu trả lời";
      if (question.type === "matching") return "Khớp các lựa chọn";
      return null;
    })();
    /* let shuffleAnswer = shuffle(question.answers) */

    return (
      <div
        className={"right-section hover hover-bg d-question-item"}
        style={{ pointerEvents: state.done ? "none" : "auto" }}
        key={i}
        hidden={!state.started && !state.review}>
        <table className={"d-table"}>
          <tbody>
            <tr>
              <td>
                <span className={"question"} style={{ fontSize: 14, lineHeight: "17px" }}>
                  <b>
                    Question {i + 1} of {state.length || questions.length}
                  </b>
                </span>
                <span hidden={!state.done} className={"question"} style={{ marginLeft: 8 }}>
                  <i className="fas fa-lock" /> Đã nộp bài
                </span>
              </td>
              <td>
                <span
                  className={"score"}
                  style={{ fontSize: 14, lineHeight: "17px", color: hintTextColor }}>
                  ({question.point} điểm)
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ padding: "0 16px" }}>
          <StyledQuestionInfo>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="8" fill="#999FAE" />
              <path
                d="M7.238 6.332H8.988V13.5H7.238V6.332ZM8.12 5.1C7.80267 5.1 7.53667 5.002 7.322 4.806C7.10733 4.60067 7 4.34867 7 4.05C7 3.75133 7.10733 3.504 7.322 3.308C7.53667 3.10267 7.80267 3 8.12 3C8.43733 3 8.70333 3.098 8.918 3.294C9.13267 3.48067 9.24 3.71867 9.24 4.008C9.24 4.316 9.13267 4.57733 8.918 4.792C8.71267 4.99733 8.44667 5.1 8.12 5.1Z"
                fill="white"
              />
            </svg>
            <span>{infoDivider}</span>
          </StyledQuestionInfo>
          <StyledDivider />
          <StyledQuestion>
            {(() => {
              if (question.type === "fill") return "Điền vào chỗ trống";
              return question.name;
            })()}
          </StyledQuestion>
          {question.type === "single" && (
            <StyledFixPosition>
              <Radio.Group
                className={"row-m-none"}
                value={state.answers[question.id]}
                onChange={(e) => {
                  let answers = _.cloneDeep(state.answers);
                  answers[question.id] = e.target.value;
                  setState({ answers });
                }}>
                <Row gutter={[16, 16]}>
                  {question.answers.map((answer, i) => (
                    <Col span={question.answers.length > 2 ? 12 : 24} key={i}>
                      <div className={"option-text"}>
                        <Radio value={answer.id}>
                          <StyledMultipleLineIndent>
                            <span>{answer.name}</span>
                            {answer.images.length > 0 && (
                              <div>
                                <img
                                  src={
                                    Configuration.imageUrl +
                                    answer.images[0].path +
                                    "?mode=crop&width=80&height=80"
                                  }
                                  alt={"Quiz"}
                                />
                              </div>
                            )}
                          </StyledMultipleLineIndent>
                        </Radio>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </StyledFixPosition>
          )}
          {question.type === "multiple" && (
            <StyledFixPosition>
              <Checkbox.Group
                className={"row-m-none"}
                value={state.answers[question.id]}
                onChange={(checkedValues) => {
                  let answers = _.cloneDeep(state.answers);
                  answers[question.id] = checkedValues;
                  setState({ answers });
                }}>
                <Row gutter={[16, 16]}>
                  {question.answers.map((answer, i) => (
                    <Col span={question.answers.length > 2 ? 12 : 24} key={i}>
                      <div className={"option-text"}>
                        <Checkbox value={answer.id}>
                          <StyledMultipleLineIndent>
                            <span>{answer.name}</span>
                            {answer.images.length > 0 && (
                              <div>
                                <img
                                  src={
                                    Configuration.imageUrl +
                                    answer.images[0].path +
                                    "?mode=crop&width=80&height=80"
                                  }
                                  alt={"Quiz"}
                                />
                              </div>
                            )}
                          </StyledMultipleLineIndent>
                        </Checkbox>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </StyledFixPosition>
          )}
          {question.type === "input" &&
            question.answers.map((answer, i) => (
              <ShortAnswer state={state} setState={setState} question={question} answer={answer} />
            ))}
          {question.type === "fill" &&
            question.answers.map((answer, i) => (
              <span key={i}>
                {question.name.split("_").map((name, i) => {
                  return (
                    <span key={i}>
                      {i > 0 && (
                        <Input
                          className={"d-fill"}
                          onFocus={(e) => e.target.select()}
                          onChange={(e) => {
                            let answers = _.cloneDeep(state.answers);
                            if (!answers[question.id]) answers[question.id] = {};
                            if (!answers[question.id][answer.id])
                              answers[question.id][answer.id] = [];
                            answers[question.id][answer.id][i] = e.target.value;
                            setState({ answers });
                          }}
                          value={
                            state.answers[question.id] && state.answers[question.id][answer.id]
                              ? state.answers[question.id][answer.id][i]
                              : null
                          }
                        />
                      )}
                      {(() => {
                        let renderText = name || "";

                        return renderText.split("\n").map((line, i) => {
                          return (
                            <React.Fragment key={i}>
                              {i > 0 && <br />}
                              <span>{line}</span>
                            </React.Fragment>
                          );
                        });
                      })()}
                    </span>
                  );
                })}
              </span>
            ))}
          {question.type === "matching" &&
            (() => {
              let answers = question.answers
                
                .filter((v) => ![null, undefined].includes(v));

              return (
                <StyledFixPosition>
                  <div className={"row-m-none"}>
                    <Row gutter={[16, 16]}>
                      {answers.map((answer, i) => {
                        
                        let show = answer.name.split("_");
                        return (
                          <React.Fragment key={i}>
                            <Col span={12}>
                              <div className={"option-text"}>{show[0]}</div>
                            </Col>
                            <Col span={12}>
                              <Select
                                style={{ width: "100%" }}
                                allowClear
                                placeholder={"Chọn đáp án"}
                                value={
                                  state.answers[question.id] && state.answers[question.id][show[0]]
                                }
                                onChange={(v) => {
                                  let answers = _.cloneDeep(state.answers);
                                  if (!answers[question.id]) answers[question.id] = {};
                                  _.forEach(answers[question.id], (v1, k) => {
                                    if (v1 === v) delete answers[question.id][k];
                                  });
                                  answers[question.id][show[0]] = v;
                                  setState({ answers });
                                }}
                                >
                                {answers.map((answer, i) => 
                                  {
                                      let show1 = answer.name.split("_");
                                      return <Select.Option value={show1[1]} key={i}>
                                      {show1[1]}
                                    </Select.Option>
                                  }
                                  
                                )}
                              </Select>
                            </Col>
                          </React.Fragment>
                        );
                        
                      })}
                    </Row>
                  </div>
                </StyledFixPosition>
              );
            })()}
        </div>
      </div>
    );
  });
}

function InfoDivider(props) {
  return (
    <div style={{ position: "relative" }}>
      <Divider />
      <div className={"divider-text"}>{props.info}</div>
    </div>
  );
}

export const CountDownState = Observable({
  seconds: -1,
  countDownTimer: -1,
  pause: false,
});

export const CountDown = Observer(function (props) {
  useUnmount(() => {
    clearInterval(CountDownState.countDownTimer);
  });
  useEffect(() => {
    clearInterval(CountDownState.countDownTimer);
    if (CountDownState.seconds <= 0) return;
    CountDownState.countDownTimer = setInterval(() => {
      if (!CountDownState.pause && CountDownState.seconds > 0) {
        CountDownState.seconds--;
      }

      if (CountDownState.seconds <= 0) {
        props.onDone && props.onDone();
        clearInterval(CountDownState.countDownTimer);
      }
    }, 1000);
  }, [CountDownState.seconds]);
  useEffect(() => {
    CountDownState.seconds = props.seconds;
    CountDownState.pause = false;
  }, [props.seconds]);

  if (CountDownState.seconds === 0) return null;

  return (
    <span
      style={{ display: "inline-block", marginLeft: 8 }}
      className={CountDownState.seconds <= 30 ? "blink-me" : undefined}>
      ({duration2Text(CountDownState.seconds, true)})
    </span>
  );
});

export default MaxQuizDetail;

/* function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
} */

export function duration2Text(seconds, forceShowSeconds) {
  if (!(seconds >= 0)) return "N/A";

  let duration = moment.duration(seconds, "seconds");
  let stringBuilder = [];
  let hasBetter = false;

  if (duration.years() > 0 || hasBetter) {
    stringBuilder.push(duration.years() + "y");
    hasBetter = true;
  }
  if (duration.months() > 0 || hasBetter) {
    stringBuilder.push(duration.months() + "mo");
    hasBetter = true;
  }
  if (duration.days() > 0 || hasBetter) {
    stringBuilder.push(duration.days() + "d");
    hasBetter = true;
  }
  if (duration.hours() > 0 || hasBetter) {
    stringBuilder.push(duration.hours() + "h");
    hasBetter = true;
  }
  if (duration.minutes() > 0 || hasBetter) {
    stringBuilder.push(duration.minutes() + "m");
    hasBetter = true;
  }

  if (duration.seconds() > 0 || !hasBetter || forceShowSeconds) {
    stringBuilder.push(duration.seconds() + "s");
  }

  return stringBuilder.join(" ");
}
